.cartpage {
    width: 95%;
    min-height: 100vh;
    margin: 15px auto;
    padding: 20px;
    background: white;
    border-radius: 2px;

    display: flex;
    justify-content: space-between;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    -ms-border-radius: 2px;
    -o-border-radius: 2px;/* Make sure items stack vertically on small screens */
  }
  
  .cart-title-container {
    display: flex;
    justify-content: space-between;
    margin: 5px 0;
    align-items: center;
  }
  .cart-title-container h5 {
    color: rgba(0, 0, 0, 0.5);
    margin-right: 10px;
  }
  
  .cart-title {
    text-align: start;
    color: #333;
  }
  
  .cart-items {
    display: flex;
    flex-direction: column;
    position: relative;
    gap: 15px;
    width: 70%;
    padding: 40px;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.39);
     /* Adjust to full width on smaller screens */
  }
  
  .cart-item {
    display: flex;
    align-items: center;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    padding: 10px;
    height: auto;
    min-height: 100px; /* Allow height to adjust based on content */
  }
  
  .cart-item-image {
    width: 80px;
    height: 80px;
    object-fit: cover;
    border-radius: 1px;
    -webkit-border-radius: 1px;
    -moz-border-radius: 1px;
    -ms-border-radius: 1px;
    -o-border-radius: 1px;
}
  
  .cart-item-details {
    margin-left: 15px;
    display: flex;
    flex-direction: column;
    height: 100%; /* Allow height to adjust based on content */
    justify-content: space-between;
    flex: 1;
  }
  
  .cart-title-button {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .cart-item-title {
    font-size: 13px;
    font-weight: 600;
    margin: 0;
    color: #333;
    width: 100%; /* Adjust to full width */
  }
  
  .cart-item-remove {
    background: transparent;
    width: 20px;
    height: 20px;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .cart-item-price {
    font-size: 14px;
    font-weight: 600;
    margin: 5px 0;
    color: #777;
  }
  
  .quantity-price {
    display: flex;
    justify-content: space-between;
    align-items: center; /* Center align on small screens */
  }
  
  .quantity {
    display: flex;
    width: auto; /* Adjust to full width */
    gap: 15px;
    align-items: center;
  }
  
  .cart-quantity-btn {
    background-color: #f0f0f0;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
    border-radius: 3px;
    width: 25px;
    height: 25px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    -o-border-radius: 3px;
  }
  
  .plus {
    background-color: #e63946;
    color: white;
  }
  
  .minus:hover {
    background-color: #e0e0e0;
  }
  
  .cart-quantity-display {
    font-size: 14px;
    font-weight: 600;
  }
  .input-error {
    border: 2px solid red;
}

.error-message {
    color: red;
    font-size: 12px;
    margin-top: 5px;
}
  .empty-cart {
    position: absolute;
    color: #555;            
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  
  .btn-back-to-shop {
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 13px;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.863);
    border: none;
    background-color: white;
    cursor: pointer;
    position: absolute;
    bottom: 20px;
    left: 20px;
}
  
  
  .btn-back-to-shop:hover {
    background-color: white;
  }
  
  .btn-back-to-shop img {
    width: 20px;
    height: 20px;
  }
  .info-summary-container { /* This allows it to grow and shrink as needed */
    width: 28%;
    display: flex;
    gap: 10px;
    flex-direction: column;
  }
  .cart-footer {

    padding: 25px;
    padding-left: 15px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.39);
}
.Order-summary {
    border-bottom: 2px solid rgba(0, 0, 0, 0.5);
    padding-bottom: 10px;
  }
  .cart-footer h5 {
    display: flex;
    justify-content: space-between;
    color: rgba(0, 0, 0, 0.5);
  }
  .cart-footer h5  span{
    color:rgba(0, 0, 0, 0.9);
    margin-left: 20px;
  }
  .subtotale{
    border-bottom: 2px solid rgba(0, 0, 0, 0.5);
    padding-bottom: 10px;
  }
  .totale{
    display: flex;
    justify-content: space-between;
    color: rgba(0, 0, 0, 0.5);
  }
  .totale span {
    color:rgba(0, 0, 0, 0.9);
  }

  .client-delivery-info{
    padding: 15px;
    padding-left: 15px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
    min-height: 400px;
    max-height: 500px;
    position: relative;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.39);
  }
  .info-inputs{
    display: flex;
    flex-direction: column;
    gap: 5px;
    height: 90%;
    
  }
  .info-inputs input ,select {
    background-color: #F2F2F2;
    border: none;
    width: 100%;
    height: 20%;
    font-weight: 600;
    outline: none;
    font-size: 12px;
    color: #333;
    padding-left: 20px;
    border-radius: 1px;
    -webkit-border-radius: 1px;
    -moz-border-radius: 1px;
    -ms-border-radius: 1px;
    -o-border-radius: 1px;
}
.address-container{
    display: flex;
    justify-content: space-between;
    background-color: #F2F2F2;
    align-items: center;
    border-radius: 1px;
    height: 20%;
    width: 100%;
    -webkit-border-radius: 1px;
    -moz-border-radius: 1px;
    -ms-border-radius: 1px;
    -o-border-radius: 1px;
}
.address-container input{
    height: 100%;
    width: 90%;
}
.map-button{
    border: none;
    outline: none;
    background-color: transparent;
    cursor: pointer;
    width: 30px;
    height: 30px;
    padding: 5px;

}
.map-button img{

    width: 100%;
    height: 100%;
}
.map-button:hover{
    background-color: transparent;
    
}

.checkout-button {
    background: #e63946;
    border: none;
    height: 10%;
    width: 100%;
    color: #fff;
    border-radius: 2px;
    font-size: 13px;
    cursor: pointer;
    font-weight: 600;
    text-align: center;
  }
  .cart-item-languages {
    display: flex;
    gap: 10px;
}




.checkbox-container input[type="radio"] {
  display: none;
}

/* Style for the labels */
.checkbox-container label {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ddd;
  cursor: pointer;
  font-size: 13px;
  font-weight: 600;
  color: black;
  border: none;
  transition: background-color 0.3s ease;
}

/* Style for the selected radio button */
.checkbox-container input[type="radio"]:checked + label {
  background-color: #e63946;
  color: white;
}

/* Hover effect */
.checkbox-container label:hover {
  background-color: #c53030;
  color: white;
}
    /* Media Queries for Responsiveness */
    @media (max-width: 992px) {
        .cartpage {
            width: 95%;
            flex-direction: column;
            padding: 15px;
            gap: 20px;
            justify-content: flex-start;
        }

        .cart-items{
            width: 100%;
            height: auto;
            padding: 15px;
            min-height: 200px;
            padding-bottom: 40px;
        }
        .info-summary-container {
            width: 100%;
            display: flex;
            gap: 20px;
            flex-direction: column;
        }
        .cart-item-title {
            font-size: 14px;
            font-weight: 600;
        }
        .btn-back-to-shop {
            bottom: 10px;
            left: 10px;
            width: 80%;
    
        }
        .client-delivery-info {
            padding: 30px;
            gap: 20px;
            min-height: 400px;
            max-height: 700px;
    
    
        }
        .info-inputs {
            gap: 10px;
            /* height: 90%; */
            /* height: 100%; */
            height: 330px;
        }
        .info-inputs input ,select {
            min-height: 40px;
        }
        
        
        .checkout-button {
    
            min-height: 40px;
    
        }
      }
  
  /* Media Queries for Responsiveness */
  @media (max-width: 768px) {
    .cartpage {
      width: 95%;
      flex-direction: column;
      padding: 15px;
    }
    .cart-items{
        width: 100%;
        height: auto;
        padding: 10px;
        min-height: 200px;
        padding-bottom: 40px;
    }
 
    .cart-item-details {
      gap: 20px;

  }
    .cart-item-title {
        font-size: 14px;
        font-weight: 600;
    }
    .btn-back-to-shop {
        bottom: 10px;
        left: 10px;
        width: 80%;

    }
    .client-delivery-info {
        padding: 30px;
        gap: 20px;
        min-height: 400px;
        max-height: 700px;


    }
    .info-inputs {
        gap: 10px;
        /* height: 90%; */
        /* height: 100%; */
        height: 330px;
    }
    .info-inputs input ,select {
        min-height: 40px;
    }
    
    
    .checkout-button {

        min-height: 40px;

    }
  }
  
  @media (max-width: 480px) {
    .cartpage {
      width: 100%;
      padding: 0px;
      display: flex;
      flex-direction: column;
      gap: 20px;
      margin: 0;
    }
    .quantity-price {
      gap: 5px;
  }
    .info-summary-container {
      width: 100%;
      max-width: 100%;
    }
    .cart-item-languages {
      display: flex;
      gap: 5px;
  }
  .quantity {
    gap: 10px;
}
.checkbox-container label {
  width: 30px;
  height: 30px;
  font-size: 13px;
  font-weight: 600;
}
.cart-item {
  padding: 5px;

}
  }