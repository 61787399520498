* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
body, html {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  /* font-family: "Kufam", sans-serif;  */
}

body {
  background-color: white; /* Light gray background */
  color: #333;
}

a {
  text-decoration: none;
  color: inherit;
}

ul {
  list-style-type: none;
}
