.footer {
    background-color: #2A323F;
    color: #fff;
    padding: 20px 0;
}

.footer-container {
    display: flex;
    position: relative;
    align-items: center;
    width: 100%;
    flex-direction: column; /* Allows wrapping on smaller screens */
    gap: 0px;
    padding: 20px;
}

.footer-section {
    position: relative;
    display: flex;
    gap: 30px;
    justify-content: center;
    margin: 0px 15px;
    min-width: 200px;
    align-items: center;
}

.footer-section a img {
    width: 20px;
    height: 20px;
    margin-right: 10px;
}

.footer-section p img {
    width: 20px;
    height: 20px;
    margin-right: 10px;
    cursor: pointer;
}



.footer-section p {
    margin: 15px 0;
    font-size: 13px;
    display: flex;
}

.footer-section a {
    color: #fff;
    text-decoration: none;
}

.footer-section a:hover {
    text-decoration: underline;
}
.copy{
    position: absolute;
    top: 100%;
    color: black;
    font-weight: 600;
    left: 50%;
    padding: 5px;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    background-color: #fff;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.247);
    -o-transform: translate(-50%, -50%);
}

/* Ensure responsiveness */
@media (max-width: 768px) {
    .footer-container {
        gap: 5px;
    }


    .footer-section p {
        text-align: center;
    }
}

.footer-bottom {
    border-top: 1px solid #444;
    padding-top: 10px;
    text-align: center;
}
.footer-bottom p {
    font-size: 13px;
}
