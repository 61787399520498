:root {
    --red: #DF2049;
}

.navbar {
    background-color: white; /* Background color of the Navbar */
    height: 12vh;
    width: 100vw;
    display: flex;
    justify-content: space-around;
    align-items: center;
    border-bottom: 3px solid var(--red);
}

.navbar-logo img {
    width: 130px;
}

.navbar .cart-container .cart {
    width: 30px;
    cursor: pointer;
}

.navbar-menu {
    list-style: none;
    display: flex;
    gap: 40px;
    transition: opacity 0.3s ease, max-height 0.3s ease;
    opacity: 1; /* Default opacity */
    max-height: 100px; /* Adjust based on content size */
    overflow: hidden; /* Hide overflow content */
}

.navbar-item {
    margin: 0;
}

.navbar-link {
    color: black;
    text-decoration: none;
    font-size: 16px;
    transition: color 0.3s ease;
}

.navbar-link:hover {
    color: #DF2049;
    transform: scale(1.1);
    -webkit-transform: scale(1.1);
    -moz-transform: scale(1.1);
    -ms-transform: scale(1.1);
    -o-transform: scale(1.1);
}

.hamburger {
    background-image: url('../../assets/menu.png');
    width: 35px;
    height: 35px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: none;
    cursor: pointer;
}
.cart-container span{
       width: 20px;
       height:20px;
       background-color: #DF2049;
       color: white;
       display: flex;
       justify-content: center;
       align-items: center;
       font-weight: 600;

       border-radius: 50%;
       position: absolute;
       top: -3px;
       right: -3px;
}
.cart-updated {
    background-color: red;
    border-radius: 50%;
    padding: 5px;
    transition: background-color 0.3s ease;
}
@media (max-width: 768px) {
    .navbar {
        flex-direction: row;
        height: auto;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
    }

    .navbar-logo img {
        margin-left: 20px;
        margin-top: 20px;
    }
    
    .navbar-menu {
        display: flex; /* Show menu on small screens */
        flex-direction: column;
        align-items: center;
        width: 100%;
        gap: 20px;
        margin-top: 10px;
        opacity: 0;
        max-height: 0;
        overflow: hidden;
        transition: opacity 0.3s ease, max-height 0.3s ease;
        margin-bottom: 10px;

    }

    .navbar-menu.active {
        opacity: 1;
        max-height: 500px; /* Adjust based on content size */
    }
    .cart-container{
        position: fixed;
        bottom: 20px;
        right: 20px;
        background-color: white;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        -ms-border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.3);
        -o-border-radius: 50%;
        z-index: 99;
}


    .hamburger {
        display: block;
        margin-right: 30px;
        margin-top: 10px;

    }
}
