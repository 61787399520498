.product-container {
    width: 100vw;
    min-height: 100vh;
    position: relative;
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
  }
  
  .back-button {
    position: absolute;
    left: 20px;
    top: 20px;
    border-radius: 50%;
    box-shadow: 0px 0px 10px rgba(0, 0, 0 ,0.5);
    border: none;
    cursor: pointer;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
}
.back-button img {
    width: 100%;
    height: 100%;
}
  
  .back-button:hover {
    background-color: #e0e0e0;
  }
  
  .product-content {
    position: relative;
    display: flex;
    width: 90%;
    gap: 20px;
    padding: 20px;
    box-shadow: 0px 0px 5px rgba(0, 0, 0,0.5);
    border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    box-sizing: border-box; /* Include padding in width/height */
    background-color: white;
    -o-border-radius: 3px;
}
  
  .product-content .product-image {
    width: 40%;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}
  
  .product-details {
    width: 50%;
    display: flex;
    flex-direction: column;
    padding-left: 20px;
    padding-top: 20px;
  }
  
  .product-title {
    margin-bottom: 20px;

  }
  
  .product-description {
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 20px;
    margin-right: 30px;
  }
  
  .product-category, .product-price {
    font-size: 20px;
    margin-bottom: 10px;
  }
  .product-category span, .product-price span {
    margin-left: 20px;
    font-size: 18px;
  }
  .product-price-container{
    margin: 15px 0px;
    display: flex;
    gap: 30%;
    align-items: center;
  }
  
  .quantity-controls {
    display: flex;
    align-items: center;
    margin-top: 10px;
  }
  
  .quantity-btn {
    background-color: #f0f0f0;
    box-shadow: 0px 0px 10px rgba(0, 0, 0,0.5);
    border: none;
    display:flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
    border-radius: 3px;
    width: 30px;
    height: 30px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    -o-border-radius: 3px;
}
  .plus{
    background-color: #e63946;
    color: white;
  }
  .minus:hover{
    background-color: #e0e0e0;
  }
  
  .quantity-display {
    font-size: 22px;
    font-weight: 600;
    margin: 0 25px;
  }
  
  .product-buttons {
    display: flex;
    gap: 10px;
    margin-bottom: 20px;
  }

  
  .add-to-cart, .buy-now {
    background-color: #f0f0f0;
    box-shadow: 0px 0px 10px rgba(0, 0, 0,0.5);
    border: none;
    display:flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    font-size: 14px;
    cursor: pointer;
    width: 150px;
    font-weight: 600;
    border-radius: 3px;
    margin-top: 40px;
  }
  .buy-now {
    background-color: #e63946;
    color: white;
  }
  
  .add-to-cart:hover {
    background-color: #e0e0e0;
  }
  .added-message{
    position: absolute;
    bottom: 50%;
    right: 50%;
    transform: translate(50%, 50%);
    background-color: white;
    border-radius: 2px;
    color: #c53030;
    width: auto;
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    box-shadow: 0px 0px 5px black;
    height: auto;
    z-index: 1001;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    -ms-border-radius: 2px;
    -o-border-radius: 2px;
}



.best_seller{
  position: absolute;
  top: 0px;
  right: 0px;
  width:100px;
  height:100px;
}

.loading{
  width: 100vw;
  min-height: 90vh;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-weight: 600;
}

@media (max-width:992px) {
  .product-content .product-image {
    width: 50%;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}
  
  .product-details {
    width: 50%;
    display: flex;
    flex-direction: column;
    padding-left: 0px;
  }
  
  
}
@media (max-width:768px) {
   .product-container {
    padding:30px  5px;
    position: relative;
    }
    .product-container .back-button {
        position:absolute;
       top: 5px;
       left: 5px;
       z-index: 99;
       background-color: white;

    }
    
    
    .back-button {
        left: 5px;
        top: 10px;
        width: 30px;
        height: 30px;

    }

      
      .product-content {
        flex-direction: column;
        width: 95%;
        padding: 10px;
    }
      
      .product-content .product-image {
        width: 100%;
        height: auto;
        border-radius: 5px;
        object-fit: cover;
        -webkit-border-radius: 5px;
        -moz-border-radius: 5px;
        -ms-border-radius: 5px;
        -o-border-radius: 5px;
    }
    .category-lang-container{
        flex-direction: column;
        gap: 20px;
        margin-bottom: 10px;
        align-items: flex-start;
  
  }
  .category-lang-container-lang{
    display: flex;
    width: 100%;
    justify-content: flex-start;

  }
      .product-details {
        width: auto;
        display: flex;
        flex-direction: column;
        padding:10px;
      }
      
      .product-title {
        margin-bottom: 20px;
      }
      
      .product-description {
        margin-right: 0px;
      }
      
      .quantity-controls {
        justify-content: center;
      }

      .best_seller{
        position: absolute;
        top: 0px;
        right: 0px;
        width:80px;
        height:80px;
      }
    
}
  