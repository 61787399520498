.contact-page {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    padding: 20px;
    width: 100vw;
    position: relative;
    min-height: 88vh;
    background-color: #f9f9f9;
  }
  /* src/styles/global.css */

  .contact-info {
    flex: 1 1 45%;
    max-width: 45%;
    padding: 20px 0px ;
  }
  
  .contact-info h2 {
    font-size: 2em;
    margin-bottom: 10px;
  }
  
  .contact-info .highlight {
    color: #e63946;
  }
  
  .contact-info p {
    margin-bottom: 20px;
    /* line-height: 1.6; */
    margin-left: 30px;
    font-weight: 500;
    font-size: 14px;
  }
  
  .info {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 40px;
  }
  
  .info-item {
    display: flex;
    gap: 20px;
    flex-direction: column;
    flex: 1;
  }
  
  .info-title {
    margin-left: 40px;
    font-size: 14px;
    font-weight: 600;
    display: flex;
    align-items: center;
    gap: 10px;
  }
  .info-title img {
    height: 20px;
    width: 20px;
    margin: 10px;

  }
  .info-item a {
    text-align: center;
    font-size: 15px;
    font-weight: 600;
    /* margin-left: 100px; */
  }
  
  .infos-container{
      display: flex;
  }





  .social-links {
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    gap: 30px;
  }
  
  .social-links .socialmedia-container {
    display: flex;
    gap: 30px;
    margin-left: 50px;
  }
  
  .social-icon  img {
    width: 30px;
    height: 30px;
  }

  


  .swap{
    position: absolute;
    display: flex;
    width: auto;
    min-width: 200px;
    padding: 20px;
    height: auto;
    background-color: white;
    font-size: 14px;
    font-weight: 600;
    color: #e63946;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.295);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    justify-content: center;
    align-items: center;
    text-align: center;
    -webkit-transform: translate(-50);
    -moz-transform: translate(-50);
    -ms-transform: translate(-50);
    -o-transform: translate(-50);
}

  
  .contact-form {
    width: 400px;
    background: white;
    padding: 20px;
    margin-top: 20px;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.336);
    position: relative;
    z-index: 1;
  }
  .contact-form form {
    width: 100%;
    height: 90%;
  }




  .contact-form h3 {
    font-size: 1.5em;
    margin-bottom: 15px;
  }
  


  .form-group {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
  }
  .first {
    display: flex;
    gap: 20px;
  }
  
  .form-group label {
      font-size: 12px !important;
      font-weight: 600 !important;
      margin-bottom: 10px;
    }
  
  .form-group input,
  .form-group textarea {
    padding: 10px;
    border: none;
    border-radius: 1px;
    font-size: 12px;
    width: 100%;
    background-color: #F2F2F2;
    -webkit-border-radius: 1px;
    -moz-border-radius: 1px;
    -ms-border-radius: 1px;
    -o-border-radius: 1px;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.274);
}
  .form-group textarea {
    height:120px;
  }
  
 


  
  .contact-form button {
    background-color: #e63946;
    color: white;
    padding: 10px 20px;
    border: none;
    width: 40%;
    height: 10%;
    border-radius: 1px;
    cursor: pointer;
    font-size: 12px;
    font-weight: 600;
    display: block;
    margin: 0 auto;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.274);
     /* Centers the button horizontally */
}
  
  button:hover {
    background-color: #d62736;
  }
  .book-image{
    position: absolute;
    right: 0;
    top: 0;
    height: 88vh;
    width: auto;
    z-index: 0;
  }

  html.rtl .book-image {
    position: absolute;
    left: 0px;
}

  @media screen and (max-width: 768px) {
    .contact-page {
      flex-direction: column;
    }
    .info-item a {
      text-align: center;
      /* margin-left: 100px; */
    }
    .contact-info,
    .contact-form {
      max-width: 100%;
      margin-right: 0;
    }
  
    .form-group input[type="text"] {
      width: 100%;
    }
    .book-image{
     display: none
  }}
  
  

  @media screen and (max-width: 480px) {
    .info-item a {
      text-align: center;
      /* margin-left: 100px; */
    }
    .info-title {
      margin-left: 0px;
}
.book-image{
  display: none;
  position: absolute;
  right: 0;
  top: 0;
  height: 88vh;
  width: auto;
  z-index: 0;
}

  }
  
